import React from "react";
import _ from "lodash";
import { navigate } from "@reach/router";
import { FiChevronLeft } from "react-icons/fi";

import { Layout } from "../components/index";
import CopyLink from "../components/CopyLink";
import { safePrefix, htmlToReact, Link } from "../utils";

export default class Page extends React.Component {
  render() {
    const noBack = typeof window === "undefined" || window.history.length === 2;
    return (
      <Layout {...this.props}>
        <div className="outer">
          <div className="inner-medium">
            <div className="guide-header-links">
              <Link
                className="backlink"
                onClick={() => {
                  if (noBack) {
                    navigate("/resources/guides");
                  } else {
                    window.history.back();
                  }
                }}
              >
                <FiChevronLeft /> {noBack ? "See all guides" : "Go back"}
              </Link>
              <Link>
                <CopyLink text="Copy link to guide" copiedText="Link copied" />
              </Link>
            </div>
            <article className="guide post post-full">
              <header className="guide-header">
                <h1 className="guide-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(this.props, "pageContext.frontmatter.img_path") && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.img_path")
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.alt-text")}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
              <div className="guide-content">
                {htmlToReact(
                  // we maintain good semantic of headings tags but suitable style
                  _.get(this.props, "pageContext.html")
                    .replace(/h2>/g, "h3>")
                    .replace(/h1>/g, "h2>")
                )}
              </div>
              <div className="support-block">
                <h6>Not good enough?</h6>
                <p>
                  If things are still not clear, or if you would like to suggest
                  some changes to this page, please feel free to contact our
                  support team.
                </p>
                <Link to="/support" className="button secondary">
                  Contact support
                </Link>
              </div>
            </article>
          </div>
        </div>
      </Layout>
    );
  }
}
